import StakingPoolABI from '../abi/StakingPoolABI.json';

export const poolData = [
  {
    id: 'pool1',
    label: '28 Days',
    apr: 15,
    ethPerencent: 10,
    lockupPeriod: 28,
    address: '0x6294ed7078fe1dB4ed55de57407509BD7B5D0bbb', // Replace with actual contract address
    tokenPoolAddress: '0x332BAd418651FFE1AA7ae27F8D04E3AFbfe2dDd9', // Replace with actual token pool address
    abi: StakingPoolABI
  },
  {
    id: 'pool2',
    label: '56 Days',
    apr: 30,
    ethPerencent: 30,
    lockupPeriod: 56,
    address: '0x655B6Dd956e7b57b13306fFfbe6f3B4D7a1e68Fa', // Replace with actual contract address
    tokenPoolAddress: '0xDCca667EdD0cB7E233b4718626015beF2C698B53', // Replace with actual token pool address
    abi: StakingPoolABI
  },
  {
    id: 'pool3',
    label: '84 Days',
    apr: 60,
    ethPerencent: 60,
    lockupPeriod: 84,
    address: '0xE1ECE5015912D4862c99fe6CDF02ACaf38cfbabB', // Replace with actual contract address
    tokenPoolAddress: '0x80433e30E94154319571eD4e829642c78Bb1564C', // Replace with actual token pool address
    abi: StakingPoolABI
  }
];

export const TOKEN_ADDRESS = '0x8e0EeF788350f40255D86DFE8D91ec0AD3a4547F';
export const TOKEN_TICKER = "$COR"